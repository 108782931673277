import React from "react"
import { withStyles } from "@material-ui/core"
import { GatsbyImageBackground } from "../../images"
import { ContentContainer } from "./content-container"
import { PrevLink } from "./prev-link"
import Typography from "@material-ui/core/Typography"

function BlogTopBlock(props) {
  const { classes, bgName, title } = props

  const ContentBlock = (
    <ContentContainer>
      <PrevLink
        text={"Back"}
        to={"/"}
        rel={""}
      />
      <Typography
        variant='h1'
        className={classes.title}
      >
        {title}
      </Typography>
    </ContentContainer>
  )

  if (!bgName) return (
    <div className={classes.container}>
      {ContentBlock}
    </div>
  )

  return (
    <div className={classes.container}>
      <GatsbyImageBackground
        name={bgName}
      >
        {ContentBlock}
      </GatsbyImageBackground>
    </div>
  )
}

const styles = theme => {
  return {
    container: {},
    title: {
      maxWidth: 770,
      marginTop: 30,
      fontSize: 64,
      lineHeight: "65px",
      fontWeight: 700,
      color: "#1A1A1A",
      letterSpacing: "-1px",

      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
        lineHeight: "40px",
      }
    }
  }
}

// eslint-disable-next-line
BlogTopBlock = withStyles(styles)(BlogTopBlock)

export { BlogTopBlock }
