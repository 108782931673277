import React from "react"
import { withStyles } from "@material-ui/core"
import { Link } from "gatsby"
import ArrowBack from "@material-ui/icons/ArrowBack"

function PrevLink(props) {
  const { classes, text, to, rel } = props

  return (
    <Link
      to={to}
      rel={rel}
      className={`${classes.linkStyle} ${classes.liContainer}`}
    >
      <ArrowBack
        className={classes.arrowIcon}
      />&nbsp;
      <span className={classes.underlinedText}>
        {text}
      </span>
    </Link>
  )

}

const styles = theme => {
  return {
    arrowIcon: {
      fill: "#ffab13",
      fontWeight: "bold",
      width: "24px",
      height: "24px",
    },
    liContainer: {
      display: "flex",
      justifyContent: "flex-start",
      padding: "13px 0",
      alignItems: "center",
      "&:hover > svg": {
        fill: "#e69400",
      },
    },
    linkStyle: {
      boxShadow: "none",
      position: "relative",
      zIndex: "99",
    },
    underlinedText: {
      fontWeight: "800",
      color: "#ffab13",
      fontFamily: "Europa, sans-serif",
      backgroundColor: "transparent",
      cursor: "pointer",
      "&:hover": {
        color: "#e69400",
      },
    },
  }
}

// eslint-disable-next-line
PrevLink = withStyles(styles)(PrevLink)

export { PrevLink }
