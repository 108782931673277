import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withStyles } from "@material-ui/core"
import { BlogTopBlock } from "../components/molecules/blog-top-block"
import { ContentContainer } from "../components/molecules/content-container"
import { ArticlesList } from "../components/organisms/articles-list"
import Typography from "@material-ui/core/Typography"


class BlogPostTemplate extends React.Component {
  render() {
    const { classes, data = {}, pageContext = {} } = this.props
    const { blogPrev, site, markdownRemark, allMarkdownRemark } = data
    const post = markdownRemark
    const posts = allMarkdownRemark.edges
    const siteTitle = site.siteMetadata.title

    console.log('post', post)


    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          imagePath={blogPrev && blogPrev.publicURL}
        />

        <BlogTopBlock
          title={post.frontmatter.title}
          bgName={post.frontmatter.topImg}
        />

        <ContentContainer>
          <div
            className={classes.articleContent}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </ContentContainer>
        <ContentContainer>
          <Typography
            variant='h2'
            className={classes.title}
          >
            Other articles
          </Typography>
          <ArticlesList
            posts={posts}
          />
        </ContentContainer>
      </Layout>
    )
  }
}

const styles = (theme) => {
  return {
    title: {
      fontSize: 54,
      lineHeight: "55px",
      fontWeight: 700,
      marginBottom: 100,
      letterSpacing: "-1px",
      color: "#1A1A1A",

      [theme.breakpoints.down("xs")]: {
        marginBottom: 50,
        fontSize: 30,
        lineHeight: "40px",
      },
    },
    articleContent: {
      maxWidth: 770,

      "& p, & li": {
        fontSize: 24,
        lineHeight: "38px"
      }
    }
  }
}

export default withStyles(styles)(BlogPostTemplate)

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        topImg
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            preview
          }
        }
      }
    }
    blogPrev: file(relativePath: { eq: "1.png" }) {
      extension
      publicURL
    },
  }
`
